import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { navigate, graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

import '@fontsource/roboto';

import 'prismjs/themes/prism-okaidia.css';

import Link from './Link';
import { MDXLayoutComponents, MDXGlobalComponents } from './mdx';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  ${() => {
    /* Override PrismJS Defaults */ return null;
  }}

  pre {
    background-color: #2f1e2e !important;
    border-radius: 4px;
    font-size: 14px;
  }

  .gatsby-highlight-code-line {
    background-color: #4f424c;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 1em;
  }
`;

const NAVIGATION = [{ to: '/blog', label: 'Blog' }];

export default ({ site, frontmatter = {}, children }) => {
  const {
    title,
    description: siteDescription,
    keywords: siteKeywords,
  } = site.siteMetadata;

  const {
    keywords: frontmatterKeywords,
    description: frontmatterDescription,
  } = frontmatter;

  const keywords = (frontmatterKeywords || siteKeywords).join(', ');
  const description = frontmatterDescription || siteDescription;

  return (
    <LayoutContainer>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
        ]}
      >
        <html lang="en" />
      </Helmet>

      <GlobalStyle />

      <MDXProvider
        components={{
          ...MDXLayoutComponents,
          ...MDXGlobalComponents,
        }}
      >
        <Fragment>
          <Navbar>
            <Title onClick={() => navigate('/blog')}>jordin</Title>
            <Navigation>
              {NAVIGATION.map((navigation) => (
                <li key={navigation.label}>
                  <Link to={navigation.to}>{navigation.label}</Link>
                </li>
              ))}
            </Navigation>
          </Navbar>

          {children}
        </Fragment>
      </MDXProvider>
    </LayoutContainer>
  );
};

export const pageQuery = graphql`
  fragment site on Site {
    siteMetadata {
      title
      description
      author
      keywords
    }
  }
`;

const Title = styled.h1`
  cursor: pointer;
`;

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Navigation = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 30px 0;
  padding: 0;

  li {
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  a {
    text-decoration: none;
    color: #000 !important;
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s;
  }

  a:hover {
    border-color: #000;
  }
`;

const LayoutContainer = styled.div`
  width: 90%;
  max-width: 850px;
  margin: auto;
  font-family: Roboto;

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }
`;
